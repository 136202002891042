import React, {createContext, ReactNode, useEffect, useState} from "react";

// Firebase
import { app } from "../../backend/config";
import {
  getAuth,
  onAuthStateChanged,
  User,
  signInWithEmailAndPassword,
  signOut
} from "firebase/auth";

// Hooks
import { useAlert } from "../error/hooks/useAlert";
import { useTranslation } from "react-i18next";

// Backend
import { getCurrentUser, getFuneralCompany } from "../../backend/queries/users";

// types
import { IUsers } from "type-absenso";

// Routes
import UnauthenticatedRoute from "../../router/component/UnauthenticatedRoute";
import AuthenticatedRoute from "../../router/component/AuthenticatedRoute";
import { IFrontFuneral } from "../../backend/queries/types/frontDocument";
import { Timestamp } from "firebase/firestore";

export const AuthContext = createContext<null | {
  signin: (email: string, password: string) => void;
  signout: () => void;
  funeralState: IFrontFuneral | undefined;
  userData: IUsers<Timestamp> | undefined;
    }>(null);

const AuthProvider = () => {
  const alert = useAlert();
  const {t} = useTranslation();
  const [userData, setUserData] = useState<IUsers<Timestamp>>();
  const [userState, setUserState] = useState<User>();
  const [funeralState, setFuneralState] = useState<
    IFrontFuneral
  >();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    onAuthStateChanged(getAuth(app), async (user) => {
      setLoading(true);
      if (user) {
        setUserState(user);
        const userInDb = await getCurrentUser({
          errorAction: () =>  alert && alert.createAlert && alert?.createAlert({
            type: "error", message: t("auth.errorSignin")
          }),
          uid: user.uid,
        });
        const funeralData = await getFuneralCompany({
          errorAction: () => setUserState(undefined),
          uid: user.uid,
        });
        setFuneralState(funeralData);
        setUserData(userInDb);
      } else {
        setUserState(undefined);
      }
      setLoading(false);
    });
  }, []);

  const signin = (email: string, password: string) => {
    if (getAuth(app)) {
      signInWithEmailAndPassword(getAuth(app), email, password)
        .then(() => {
          window.location.href = "/#/";
          window.location.reload();
        })
        .catch(() => {
          alert && alert.createAlert && alert?.createAlert({
            type: "error", message: t("auth.errorSignin")
          });
        });
    }
  };

  const signout = () => {
    if (getAuth(app)) {
      signOut(getAuth(app))
        .then(() => {
          window.location.href = "/#/";
        })
        .catch(() => {
          alert && alert.createAlert && alert?.createAlert({
            type: "error", message: t("error.standard")
          });
        });
    }
  };

  const Template = ({children}: {children: ReactNode}) => {
    return(
      <AuthContext.Provider value={{
        signin,
        signout,
        userData,
        funeralState,
      }}>
        {children}
      </AuthContext.Provider>
    );
  };

  if (!loading && !userState) {
    return (
      <Template>
        <UnauthenticatedRoute />
      </Template>
    );
  }

  if (!loading && userData && userData.type.includes("funeral")) {
    return(
      <Template>
        <AuthenticatedRoute />
      </Template>
    );
  }

  return(<></>);
};

export default AuthProvider;
