import { useContext } from "react";
import { useTranslation } from "react-i18next";

// Context
import { AlertContext } from "../alertprovider";

export const useAlert = () => {
  const alertHandler = useContext(AlertContext);
  const {t} = useTranslation();

  const standardError = () => {
    if (alertHandler) {
      alertHandler.createAlert({
        type: "error",
        message: t("errorStandard")
      });
    }
  };

  return {
    createAlert: alertHandler?.createAlert,
    standardError
  };
};