import React, { ReactNode } from "react";

// Ui
import { Card, Typography, Box, SxProps, Theme, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

export const CardUi = ({title, children, sx, clearAction}: {
    title: string,
    children: ReactNode,
    sx?: SxProps<Theme>,
    clearAction?: () => void;
}) => {
  const {t} = useTranslation();
  const sxPorps = sx ? {...sx, p: 2} : {p: 2};
  return(
    <>
      <Card sx={sxPorps}>
        <Box sx={{
          display: "flex",
          justifyContent: "space-between"
        }}>
          <Typography variant="h6" component="h6">
            {title}
          </Typography>

          {clearAction && <Button
            variant="contained"
            onClick={clearAction}
          >
            {t("clear")}
          </Button>}
        </Box>

        <Box pt={0}>
          {children}
        </Box>
      </Card>
    </>
  );
};
