import React from "react";

// Type
import { IForm } from "./types";

// Form
import { Control, FieldValues, FormState, UseFormWatch } from "react-hook-form";

// Components
import { Box } from "@mui/material";
import AutoCompleteReactHookForm from "./autocomplete";
import TextFieldReactHook from "./textfield";
import DatePickerReactHooksForm from "./datepickerreacthookform";
import CheckBox from "./checkBox";

const FormComponent = ({arrayForms, control, py, watch, formState}: {
    arrayForms: Array<IForm>,
    control: Control,
    watch: UseFormWatch<FieldValues>,
    formState: FormState<FieldValues>,
    py?: number,
}) => {
  return (
    <>
      {arrayForms.map((element, i) => (
        <Box key={i} py={py ? py : 1}>
          {element.type === "Autocomplete" && element.elementAutoComplete && (
            <AutoCompleteReactHookForm
              onChangeTextField={(data) =>
                element.elementAutoComplete?.onChangeTextField &&
                element.elementAutoComplete?.onChangeTextField(data)
              }
              element={element.elementAutoComplete}
              control={control}
              valueForm={watch()}
              error={Boolean(
                formState.errors[element.elementAutoComplete.name]
              )}
            />
          )}
          {element.type === "TextField" && element.elementTextField && (
            <TextFieldReactHook
              element={element.elementTextField}
              control={control}
              error={Boolean(formState.errors[element.elementTextField.name])}
            />
          )}
          {element.type === "DatePicker" && element.elementDatePicker && (
            <DatePickerReactHooksForm
              element={element.elementDatePicker}
              control={control}
              error={Boolean(formState.errors[element.elementDatePicker.name])}
            />
          )}
          {element.type === "CheckBox" && element.elementCheckBox && (
            <CheckBox
              name={element.elementCheckBox.name}
              required={element.elementCheckBox.required}
              label={element.elementCheckBox.label}
              defaultChecked={true}
              control={control}
              color={element.elementCheckBox.color}
            />
          )}
        </Box>
      ))}
    </>
  );
};

export default FormComponent;


