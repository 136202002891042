import React from "react";

// Router
import { Navigate, Route, Routes } from "react-router-dom";
import { routing } from "../config";

// Views
import Signin from "../../views/auth/signin";
import LayoutUnConnected from "../../views/layout/layoutUnconnected";
import ResetPassword from "../../views/auth/resetPassword";

const UnauthenticatedRoute = () => {
  return(
    <LayoutUnConnected>
      <Routes>
        <Route path="*" element={
          <Navigate to={routing.auth.signin} />
        } />
        <Route path={routing.auth.signin} element={<Signin />} />
        <Route path={routing.auth.resetPassword} element={<ResetPassword />} />
      </Routes>
    </LayoutUnConnected>
  );
};

export default UnauthenticatedRoute;
