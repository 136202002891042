import React from "react";

// Icon
import {
  faUserPlus,
  // faUserShield,
  // faLink,
  // faCartShopping,
  // faShop,
  // faGear,
  faUsers
} from "@fortawesome/free-solid-svg-icons";

// Router
import { routing } from "../config";
import { Routes, Route, Navigate } from "react-router-dom";

// Views
import LayoutAdmin from "../../views/layout/layoutadmin";
import HomeDeceased from "../../views/deceased/homeDeceased";
import HomeConsultDeceased from "../../views/deceased/homeConsultDeceased";
import HomeStock from "../../views/stock/homeStock";
import HomeCandles from "../../views/candles/homeCandles";

const arrayMenu = [
  {
    path: routing.deceased.index,
    title: "deceasedHome",
    icon: faUserPlus,
  },
  {
    path: routing.deceased.consult,
    title: "consultDeceased",
    icon: faUsers
  }
  // {
  //   path: routing.candle.index,
  //   title: "addAdmin",
  //   icon: faUserShield,
  // },
  // {
  //   path: routing.candle.index,
  //   title: "changeLinkMenu",
  //   icon: faLink,
  // },
  // {
  //   path: routing.candle.index,
  //   title: "orderProducts",
  //   icon: faCartShopping,
  // },
  // {
  //   path: routing.stock.index,
  //   title: "stockHome",
  //   icon: faShop,
  // },
  // {
  //   path: routing.stock.index,
  //   title: "parameters",
  //   icon: faGear,
  // }
];

const AuthenticatedRoute = () => {
  return(
    <LayoutAdmin arrayMenu={arrayMenu} >
      <Routes>
        <Route 
          path="/"
          element={<Navigate to={routing.deceased.index} />}
        />
        <Route 
          path={routing.deceased.index}
          element={<HomeDeceased />}
        />
        <Route
          path={routing.deceased.consult}
          element={<HomeConsultDeceased />}
        />

        <Route 
          path={routing.stock.index}
          element={<HomeStock />}
        />

        <Route 
          path={routing.candle.index}
          element={<HomeCandles />}
        />
      </Routes>
    </LayoutAdmin>
  );
};

export default AuthenticatedRoute;