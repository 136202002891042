import React from "react";

// Forms
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

// Ui
import { Checkbox as Check, FormControlLabel } from "@mui/material";

// Type
import { IElementCheckBox } from "./types";
import { darkTurquoise } from "../../ui/color";

interface IPropsCheckBox extends IElementCheckBox {
    control: Control;
}

const CheckBox = ({
  name,
  required,
  label,
  control
}: IPropsCheckBox) => {
  return(
    <Controller
      name={name}
      control={control}
      rules={{required: required}}
      render={(({field: {onChange, value}}) => {
        const {t} = useTranslation();
        return(
          <>
            <FormControlLabel 
              control={
                <Check
                  onChange={onChange}
                  value={value}
                  color="primary"
                />
              }
              color={darkTurquoise}
              label={typeof label === "string" ? t(label) : label}
            />
          </>
        );
      })}
    />
  );
};

export default CheckBox;