import React, { useState } from "react";

// Colors
import { boxShadow, darkTurquoiseLight, gold } from "../../ui/color";

// Components UI
import { Box, Button, Typography } from "@mui/material";
import logo from "../../media/img/logo.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { LoadingButton } from "@mui/lab";

// Backend
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { app } from "../../backend/config";

// Forms
import { useForm } from "react-hook-form";
import FormComponent from "../../components/forms/formcomponent";
import { 
  resetPasswordForm 
} from "../../components/forms/asset/auth/resetPassword";

// I18n
import { useTranslation } from "react-i18next";

// Hooks
import { useAlert } from "../../provider/error/hooks/useAlert";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const { control, watch, formState, handleSubmit } = useForm();
  const { t } = useTranslation();
  const alert = useAlert();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const onSubmit = async (data: { email: string }) => {
    try {
      setLoading(true);
      const auth = getAuth(app);
      await sendPasswordResetEmail(auth, data.email);
      alert &&
        alert.createAlert &&
        alert?.createAlert({
          type: "success",
          message: t("auth.emailSent"),
        });
      setLoading(false);
    } catch (error) {
      alert &&
        alert.createAlert &&
        alert?.createAlert({
          type: "error",
          message: t("errorStandard"),
        });
      setLoading(false);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          maxWidth: "500px",
          minHeight: "100vh",
          margin: "0 auto",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            boxShadow: boxShadow,
            borderRadius: "10px",
            width: "100%",
          }}
        >
          <Box
            py={2}
            sx={{
              backgroundColor: darkTurquoiseLight,
              width: "100%",
              maxWidth: "600px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{
                width: "280px",
                objectFit: "cover",
                marginTop: 2,
              }}
            />
            <Typography
              variant="h4"
              component="h4"
              sx={{
                color: "white",
                textTransform: "uppercase",
                marginTop: 4,
                marginBottom: 2,
                letterSpacing: "1px",
                fontSize: (theme) => theme.spacing(3),
              }}
            >
              {t("auth.resetPassword")}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              maxWidth: "600px",
            }}
            p={4}
          >
            <Typography
              variant="h5"
              component="h5"
              sx={{
                color: gold,
                marginBottom: 2,
                fontSize: (theme) => theme.spacing(3),
              }}
            >
              {t("auth.needEmail")}
            </Typography>
            <FormComponent
              control={control}
              watch={watch}
              formState={formState}
              arrayForms={resetPasswordForm}
            />
            <Box sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 4,
            }}>
              <Button onClick={() => navigate(-1)} variant="outlined">
                {t("auth.back")}
              </Button>
              <LoadingButton
                onClick={handleSubmit(onSubmit as never)}
                id="btn-login"
                sx={{
                  width: "fit-content",
                  backgroundColor: gold,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                variant="contained"
                loading={loading}
              >
                {t("auth.sendResetPassword")}
                <ArrowForwardIosIcon
                  sx={{
                    fontSize: (theme) => theme.spacing(2),
                  }}
                />
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;
