import { createTheme } from "@mui/material";

// Color
import { darkTurquoise, gold } from "./color";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: darkTurquoise
    }
  }
});

export const customTheme = createTheme(theme, {
  components: {
    MuiButton: {
      variants: [
        {
          props: {variant: "contained"},
          style: {
            backgroundColor: darkTurquoise,
            height: theme.spacing(6),
            "&:hover": {
              background: "#002e3b",
            },
          }
        },
        {
          props: {variant: "containedWithoutHeight"},
          style: {
            color: "white",
            backgroundColor: darkTurquoise,
            "&:hover": {
              background: "#002e3b",
            },
          }
        },
        {
          props: {variant: "outlined"},
          style: {
            borderColor: darkTurquoise,
            color: darkTurquoise,
            height: theme.spacing(6),
            "&:hover": {
              background: "#002e3b",
              color: "#fff"
            },
          }
        },
        {
          props: {variant: "outlinedGold"},
          style: {
            border: `1px solid ${gold}`,
            color: gold,
            height: theme.spacing(6),
          }
        },
        {
          props: {variant: "gold"},
          style: {
            backgroundColor: gold,
            color: "white",
            border: `1px solid ${gold}`,
            "&:hover": {
              color: gold,
              backgroundColor: "white",
            }
          }
        },
        {
          props: {variant: "text"},
          style: {
            color: darkTurquoise,
            textTransform: "inherit",
            fontSize: theme.spacing(2),
            lineHeight: theme.spacing(3),
            "&:hover": {
              background: "none",
            },
          }
        },
        {
          props: {variant: "flatBtn"},
          style: {
            width: "160px",
            height: "32px"
          }
        }
      ]
    }
  }
});