import { IAssetAutoCompleteField } from "../components/forms/types";

export const formatformbackend = (
  form: {[x: string]: never | IAssetAutoCompleteField<unknown> }
) => {
  const emptyObject: {[x: string]: unknown}  = {};
  Object.entries(form).forEach(([key, value]) => {
    if (value && value.value) {
      emptyObject[key] = value.value;
    } else {
      emptyObject[key] = value;
    }
  });
  
  return emptyObject;
};