import React from "react";

// Router
import { HashRouter } from "react-router-dom";

// Ui
import { CssBaseline, ThemeProvider } from "@mui/material";
import { customTheme } from "./ui/theme";

// Provider
import AlertProvider from "./provider/error/alertprovider";
import AuthProvider from "./provider/auth/authprovider";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterDateFns";
import { TitleContextProvider } from "./components/utils/layout/titleContext";

const App = () => {
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <ThemeProvider theme={customTheme}>
        <TitleContextProvider>
          <CssBaseline />
          <HashRouter>
            <AlertProvider>
              <AuthProvider />
            </AlertProvider>
          </HashRouter>
        </TitleContextProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
