import React from "react";
import { useContext } from "react";

interface ITitleContext {
  title: string;
  setTitle: (title: string) => void;
}

const TitleContext = React.createContext<ITitleContext>({} as ITitleContext);

const TitleContextProvider = (props: { children: React.ReactNode }) => {
  const [title, setTitle] = React.useState<string>("");

  return (
    <TitleContext.Provider value={{ title, setTitle }}>
      {props.children}
    </TitleContext.Provider>
  );
};

const useTitleContext = () => {
  return useContext(TitleContext);
};

export { TitleContextProvider, useTitleContext };