import { 
  IDataFrontCreateDeceased, 
  IDeath, 
  collections, 
} from "type-absenso";
import {
  DocumentReference,
  Timestamp,
  collection,
  where,
  limit,
  getDocs,
  query,
  doc,
  updateDoc,
  arrayUnion,
} from "@firebase/firestore";
import { db } from "../config";
import { IFrontDeath } from "./types/frontDocument";
import { orderBy } from "firebase/firestore";
import { postAuth } from "./config";
import { createDeathWithFuneralUrl } from "./url";

export const addDeathProfile = async ({
  errorAction,
  data,
}: {
    errorAction: () => never;
    data: IDataFrontCreateDeceased<
    DocumentReference,
    Timestamp
  >
  }) => {
  try {
    await postAuth(createDeathWithFuneralUrl,data);
  } catch (error) {
    errorAction();
  }
};

export const searchProfileDeath = async ({
  search,
  authUser,
  uidFuneral,
}: {
  authUser: Array<DocumentReference>;
  search: string;
  uidFuneral: string;
}) => {
  const dataDeath: Array<IFrontDeath> = [];
  const deathRef = collection(db, collections.death);
  const q = query(
    deathRef,
    where("fullname", ">=", search.toUpperCase()),
    where("fullname", "<=", search.toLowerCase()+"\uf8ff"),
    where("funeral", "==", doc(db, collections.funeralCompany, uidFuneral)),
    limit(10),
  );
  const snaps = await getDocs(q);

  for await (const snap of snaps.docs) {
    const data = snap.data() as IDeath<
      DocumentReference, Timestamp
    > | undefined;

    if (data) {
      const isAuth = authUser.filter((el) => {
        return el.id === data.createBy.id;
      }).length;
  
      if (isAuth) {
        dataDeath.push({
          ...data,
          id: snap.id,
        });
      }
    }
  }

  return dataDeath;
};

export const addAdminForADeath = async ({
  errorAction,
  idDeath,
  idUser,
}: {
  errorAction: () => void;
  idDeath: string;
  idUser: string;
}) => {
  try {
    const deathRef = doc(db, collections.death, idDeath);
    const userRef = doc(db, collections.users, idUser);
    await updateDoc(deathRef, {
      admin: arrayUnion(userRef)
    });
  } catch (error) {
    errorAction();
  }
};

export const getDeathWithAdmin = async (idUser: string) => {
  const deathRef = collection(db, collections.death);
  const dataDeath: Array<IFrontDeath> = [];

  const q = query(
    deathRef,
    where("admin", "array-contains", doc(db, collections.users, idUser)),
    orderBy("fullname")
  );

  const snaps = await getDocs(q);
  for await (const snap of snaps.docs) {
    const data = snap.data() as IDeath<DocumentReference, Timestamp>;
    dataDeath.push({
      ...data,
      id: snap.id,
    });
  }

  return dataDeath;
};