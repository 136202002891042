import i18next from "i18next";
import {initReactI18next} from "react-i18next";

import resources from "./resources.json";

export type TLangAvailable = "fr" | "nl";
export const STORAGE_LANG = "lang";

export const getLang = () => {
  const lng = localStorage.getItem(STORAGE_LANG) as TLangAvailable;
  if (lng) {
    return lng;
  } else {
    localStorage.setItem(STORAGE_LANG, navigator.language.split("-")[0]);
    return navigator.language.split("-")[0] as TLangAvailable;
  }
};

i18next.use(initReactI18next).init({
  resources: resources as never,
  lng: getLang(),
  keySeparator: ".",
  fallbackLng: "fr" as TLangAvailable,
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
