import { useContext } from "react";
import {AuthContext} from "../authprovider";

export const useFuneral = () => {
  const auth = useContext(AuthContext);
  
  if (auth) {
    return auth.funeralState;
  } else {
    return undefined;
  }
};