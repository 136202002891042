/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { MutableRefObject, useState } from "react";

// Lib
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

// Ui
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  SxProps,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { gold, grayBlue } from "../../ui/color";

// I18n
import { useTranslation } from "react-i18next";
import { Upload } from "@mui/icons-material";
import { ReactNode } from "react";
import { Theme } from "@emotion/react";
import { LoadingButton } from "@mui/lab";

const ImageCropper: React.FC<{
  onCrop: (crop: File) => void;
  urlCrop: string | undefined;
  setUrlCrop: React.Dispatch<React.SetStateAction<string | undefined>>;
  imgStyle?: React.CSSProperties;
  aspectRatio?: number | null;
  defautlImg?: string;
  required?: boolean;
  customComponent?: ReactNode;
  inputRef?: MutableRefObject<any>;
  sxContainer?: SxProps<Theme>;
  id?: string;
}> = ({
  onCrop,
  urlCrop,
  setUrlCrop,
  imgStyle,
  aspectRatio,
  defautlImg,
  required,
  customComponent,
  inputRef,
  sxContainer,
  id,
}) => {
  const {t} = useTranslation();
  const [image, setImage] = useState<unknown>();
  const [cropper, setCropper] = useState<any>();

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      setOpen(true);
    };

    if (files && files.length > 0) {
      reader.readAsDataURL(files[0]);
    }
  };

  const getCropData = async () => {
    setLoading(true);
    if (typeof cropper !== "undefined") {
      const image = await cropper.getCroppedCanvas().toDataURL();
      const response = await fetch(image);
      const blob = await response.blob();
      const file = new File([blob], "cropImage", { type: "image/jpg" });

      if (image) {
        setUrlCrop(image);
        await onCrop(file);
      }
      setOpen(false);
    }
    setLoading(false);
  };

  return (
    <>
      <Box sx={{...sxContainer, width: "100%"}}>
        <Box style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
          <label
            htmlFor={id || "uploadPhoto"}
            style={{cursor: "pointer", width: "100%"}}
          >
            {customComponent ? <>
              {customComponent}
            </> : <Box sx={{
              border: `1px solid ${gold}`,
              borderRadius: theme => theme.spacing(0.5),
            }}>
              <Box sx={{
                py: urlCrop ? 2 : 8,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}>
                {urlCrop || defautlImg ? 
                  <img
                    style={imgStyle}
                    src={urlCrop || defautlImg}
                    className="max_full_width"
                    alt="User's picture"
                  /> : 
                  <FontAwesomeIcon
                    icon={faImage}
                    size="4x"
                    color={gold}
                  />}
              </Box>
              <Box py={1} sx={{
                borderTop: `1px solid ${gold}`,
                display: "flex",
                justifyContent: "space-between"
              }}>
                <Upload sx={{color: gold}} />
                <Typography sx={{textTransform: "uppercase", color: gold}}>
                  {`${t("choosePicture")} ${
                    required === false ? "("+t("optional")+")" : ""
                  }`}
                </Typography>
                <Box />
              </Box>
            </Box>}
          </label>
          <input
            ref={inputRef}
            type="file"
            accept=".jpg,.png,.jpeg,.svg"
            onChange={e => onChange(e)}
            id={id || "uploadPhoto"}
            style={{display: "none"}}
          />

        </Box>

        <Dialog
          open={open}
          fullWidth={true}
        >
          <DialogTitle sx={{
            mb: 2,
            borderBottom: `1px solid ${grayBlue}`,
            // eslint-disable-next-line max-len
            boxShadow: "0px 3px 25px -2px rgba(0, 0, 0, 0.14), 0px 2px 20px rgba(0, 0, 0, 0.02), 0px 1px 15px rgba(0, 0, 0, 0.03)"
          }}>
            {t("resizeImage")}
          </DialogTitle>
          <DialogContent>
            <>
              {image && <Cropper
                style={{ height: 400, width: "100%" }}
                aspectRatio={
                  aspectRatio ?
                    aspectRatio : aspectRatio === null ? undefined : 1
                }
                zoomTo={0}
                initialAspectRatio={1}
                src={image as never}
                viewMode={1}
                minCropBoxHeight={100}
                minCropBoxWidth={100}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
                
              />}
            </>
          </DialogContent>
          <DialogActions sx={{py: 2}}>
            <LoadingButton 
              variant="text" 
              onClick={() => setOpen(false)}
              sx={{color: gold, textTransform: "uppercase"}}
            >
              {t("cancel")}
            </LoadingButton>
            <LoadingButton
              loading={loading}
              variant={"gold" as never}
              onClick={() => getCropData()}>
              {t("btnResize")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default ImageCropper;
