import React from "react";

// Ui
import { DesktopDatePicker } from "@mui/lab";
import { TextField } from "@mui/material";

// Form
import { Controller } from "react-hook-form";
import { IAssetDatePicker } from "./types/";

// Hooks
import { useTranslation } from "react-i18next";
import { gold } from "../../ui/color";

const DatePickerReactHooksForm = ({
  element, control, error
}: IAssetDatePicker) => {
  const {t} = useTranslation();
  return(
    <Controller
      name={element.name}
      control={control}
      rules={{required: true}}
      render={({ field : {onChange, value } }) => (
        <>
          <DesktopDatePicker
            minDate={element.minDate}
            label={t(element.label)}
            inputFormat="dd/MM/yyyy"
            value={value ? value : null}
            onChange={onChange}
            renderInput={(params) => 
              <TextField
                id={element.name}
                {...params}
                variant={element.variant ? element.variant : "standard"}
                sx={{
                  width: "100%", 
                  svg: gold,
                }}
                error={error}
              />
            }
          />
        </>
      )}
    />
  );
};

export default DatePickerReactHooksForm;

