import React from "react";
import { ImgInForm } from "../../../utils/imgInForm";
import { IAssetAutoCompleteField, IForm } from "../../types";

interface IOderOCF {
    options: Array<IAssetAutoCompleteField<string>>;
    optionsAdress: Array<IAssetAutoCompleteField<string>>;
    optionsDeath: Array<IAssetAutoCompleteField<string>>;
    onChangeDeath: (data: string) => void;
    onChangeAddress: (data: string) => void;
}

export interface IOrderCandlesForm {
    deliveryAddress: IAssetAutoCompleteField<string>;
    numberOfOrders: string;
    postalCodeDelivery: string;
    product: IAssetAutoCompleteField<string>;
    death?: IAssetAutoCompleteField<string>;
    deliveryCity: string;
    deliveryCountry: string;
    firstName: string;
    linkDeceased: boolean | undefined;
    name: string;
}

export const orderCandlesForm: ({
  options,
  optionsAdress,
  onChangeAddress,
  optionsDeath,
  onChangeDeath
}: IOderOCF) => Array<IForm> = ({
  options,
  optionsAdress,
  onChangeAddress,
  optionsDeath,
  onChangeDeath
}: IOderOCF) => [
  {
    type: "Autocomplete",
    elementAutoComplete: {
      id: "product",
      label: "product",
      name: "product",
      options: options,
    },
  },
  {
    type: "TextField",
    elementTextField: {
      id: "numberOfOrders",
      label: "numberOfOrders",
      name: "numberOfOrders",
      type: "number"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      id: "firstName",
      label: "firstName",
      type: "text",
      name: "firstName"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      id: "name",
      label: "name",
      type: "text",
      name: "name"
    }
  },
  {
    type: "Autocomplete",
    elementAutoComplete: {
      id: "deliveryAddress",
      label: "deliveryAddress",
      name: "deliveryAddress",
      options: optionsAdress,
      onChangeTextField: (data) => onChangeAddress(data)
    }
  },
  {
    type: "TextField",
    elementTextField: {
      id: "deliveryCity",
      label: "deliveryCity",
      name: "deliveryCity",
      type: "text"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      id: "deliveryCountry",
      label: "deliveryCountry",
      name: "deliveryCountry",
      type: "text"
    }
  },
  {
    type: "TextField",
    elementTextField: {
      id: "postalCodeDelivery",
      label: "postalCodeDelivery",
      name: "postalCodeDelivery",
      type: "number"
    }
  },
  {
    type: "CheckBox",
    elementCheckBox: {
      name: "linkDeceased",
      required: false,
      label: "linkDeceasedQuestion"
    }
  },
  {
    type: "Autocomplete",
    elementAutoComplete: {
      id: "death",
      name: "death",
      label: "nameOfTheDeceased",
      options: optionsDeath,
      onChangeTextField: onChangeDeath,
      addComponentInOption: (
        option: IAssetAutoCompleteField<unknown>
      ) => <ImgInForm option={option} />,
    }
  }
];