import React, {useState, useMemo} from "react";

// Ui
import { CardUi } from "../cardUi";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarcode,
  faBoxArchive
} from "@fortawesome/free-solid-svg-icons";
import { LoadingButton } from "@mui/lab";
import { QrReader } from "react-qr-reader";
import { Result } from "@zxing/library";

// I18n 
import { useTranslation } from "react-i18next";
import { darkTurquoise } from "../../../ui/color";

// Form
import FormComponent from "../../forms/formcomponent";
import { useForm } from "react-hook-form";
import { searchDeathForm } from "../../forms/asset/stock/sarchDeath";
import { IAssetAutoCompleteField } from "../../forms/types";

// Backend
import { searchProfileDeath } from "../../../backend/queries/death";
import { useAlert } from "../../../provider/error/hooks/useAlert";
import { formatformbackend } from "../../../utils/formatbackend";
import { collections, IDataFrontLinkStockOrKit } from "type-absenso";
import { postAuth } from "../../../backend/queries/config";
import { linkProductUrl } from "../../../backend/queries/url";
import { useFuneral } from "../../../provider/auth/hooks/useFuneral";

export const LinkKit = () => {
  const {t} = useTranslation();
  const {watch, formState, control, handleSubmit, reset} = useForm();
  const alert = useAlert();
  const funeral = useFuneral();

  const [loading, setLoading] = useState<boolean>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openQrReader, setOpenQrReader] = useState<boolean>(false);
  const [dataKitScanned, setDataKitScanned] = useState<Array<string>>([]);

  const [optionsDeath, setOptionsDeath] = useState<
    Array<IAssetAutoCompleteField<string>>
  >();

  const goodData = () => {
    return {
      dataScanned: dataKitScanned,
      setDataScanned: setDataKitScanned,
      icon: faBoxArchive,
      title: t("kit"),
      collection: collections.kit,
    };
  };

  const dataMemo = useMemo(() => {
    if (goodData()) {
      return goodData();
    }
    return undefined;
  }, [goodData()]);

  const onResultStock = (result: Result | null | undefined) => {
    if (result && dataMemo) {
      const data = result.getText().split("/");
      const uid = data[data.length - 1];
      const duplicaData = dataMemo.dataScanned;

      if (duplicaData && !duplicaData.includes(uid)) {
        duplicaData?.push(uid);
        dataMemo.setDataScanned(duplicaData);
        setOpenQrReader(false);
      }
      
    }
  };

  const onChange = async (data: string) => {
    if (funeral) {
      try {
        const deaths = await searchProfileDeath({
          authUser: funeral?.authorizedUser,
          search: data,
          uidFuneral: funeral.id
        });
    
        if (deaths) {
          setOptionsDeath(deaths.map((el) => {
            const dateOfDeath = el.dateOfDeath.toDate();
            const dateString = dateOfDeath.toLocaleDateString("eu-FR");
            return {
              label: `${el.fullname} ${dateString}`,
              value: el.id, 
              image: el.profilePicture ? el.profilePicture : undefined
            };
          }));
        }
      } catch (error) {
        alert && alert.createAlert && alert?.createAlert({
          type: "error",
          message: t("errorStandard")
        });
      }
    }
  };

  const onLink = async (dataForm: {[x: string] : never}) => {
    setLoading(true);
    try {
      const data = formatformbackend(dataForm) as unknown as {
        death: string
      };
      const dataTosend: IDataFrontLinkStockOrKit = {
        ...data,
        products: goodData()?.dataScanned
      };
      
      await postAuth(
        linkProductUrl,
        dataTosend,
        () =>  alert && alert.createAlert && alert?.createAlert({
          type: "error",
          message: t("errorStandard")
        })
      );
      setOpenModal(false);
      dataMemo?.setDataScanned([]);
      alert && alert.createAlert && alert?.createAlert({
        type: "success",
        message: t("successLink")
      });
      reset();
    } catch (error) {
      alert && alert.createAlert && alert.standardError();
    }
    setLoading(false);
  };

  /*useEffect(() => {
    document.getElementsByTagName("video").item(0)?.remove();
  }, [value]);*/

  return(
    <>
      <CardUi title={t("titleLinkStock")} >
        <Button
          sx={{width: "100%", mt: 2, p: 0 }}
          onClick={() => {
            document.getElementsByTagName("video").item(0)?.remove();
            setOpenQrReader(
              openQrReader ? false : true
            );
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "400px",
              borderStyle: "dotted",
              border: `1px dotted ${darkTurquoise}`,
              cursor: "pointer"
            }}
          >
            {!openQrReader && <FontAwesomeIcon
              icon={faBarcode}
              color={darkTurquoise}
              size="3x"
            />}

            
            {openQrReader && <>
              <QrReader
                scanDelay={500}
                videoContainerStyle={{
                  position: "inherit"
                }}
                containerStyle={{
                  width: "100%",
                  height: "100%",
                  display: openQrReader ? "block" : "none"
                }}
                constraints={{facingMode: "user"}}
                onResult={onResultStock}
              />
            </>}
            
          </Box>
        </Button>

        <List sx={{pt: 2}}>
          {
            dataMemo &&
          dataMemo.dataScanned?.length > 0 && dataMemo.dataScanned.map((
              elt, i
            ) => (
              <ListItem key={i}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={dataMemo.icon} color={darkTurquoise} />
                </ListItemIcon>
                <ListItemText>{elt}</ListItemText>
              </ListItem>
            ))}
        </List>

        <Dialog
          open={openModal}
          onClose={() => setOpenModal(false)}
          fullWidth={true}
        >
          <DialogTitle>
            {t("modalLinkStock", {type: dataMemo?.title})}
          </DialogTitle>
          <DialogContent>
            <FormComponent 
              watch={watch}
              formState={formState}
              control={control}
              arrayForms={searchDeathForm({
                options: optionsDeath || [],
                onChange,
              })}
            />
          </DialogContent>
          <DialogActions>
            <LoadingButton 
              loading={loading}
              variant="contained"
              disabled={!watch("death")}
              onClick={handleSubmit(onLink as never)}
            >
              {t("link")}
            </LoadingButton>
          </DialogActions>
        </Dialog>

        <Box sx={{width: "100%"}}>
          <LoadingButton
            disabled={!(dataMemo && dataMemo?.dataScanned.length > 0)}
            onClick={() => setOpenModal(true)}
            variant="contained"
            sx={{width: "100%"}}
          >
            {t("link")}
          </LoadingButton>
        </Box>
      </CardUi>
    </>
  );
};