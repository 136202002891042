import { useContext } from "react";
import {AuthContext} from "../authprovider";

export const useUser = () => {
  const auth = useContext(AuthContext);

  if (auth) {
    return auth.userData;
  } else {
    return undefined;
  }
};