import React from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export interface IvisibilityIcon {
    showPassword: () => void;
    visibility: boolean;
}

export default function VisibilityIcon({
  showPassword, 
  visibility
}:IvisibilityIcon) {
  return (
    <>
      <IconButton onClick={showPassword}>
        {!visibility ? <Visibility/> : <VisibilityOff/>}
      </IconButton>
    </>
  );
}