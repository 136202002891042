import React from "react";

// Ui
import { Grid } from "@mui/material";
import { LinkKit } from "../../components/utils/stock/linkStock";
import { LinkKitForm } from "../../components/utils/stock/linkKitForm";

const HomeStock = () => {
  return(
    <>
      <Grid container spacing={2} sx={{mb: 8}}>
        <Grid item md={12} xs={12}>
          <LinkKit />
        </Grid>
        <Grid item md={6} xs={12}>
          <LinkKitForm />
        </Grid>
      </Grid>
    </>
  );
};

export default HomeStock;