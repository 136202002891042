import React from "react";
import { ImgInForm } from "../../../utils/imgInForm";
import { IAssetAutoCompleteField, IForm } from "../../types";

interface IPropsISearchDeath {
    options: Array<IAssetAutoCompleteField<string>>,
    onChange: (data: string) => void;
}

export const searchDeathForm: ({
  options,
  onChange
}: IPropsISearchDeath) => Array<IForm> = ({
  options,
  onChange
}: IPropsISearchDeath) => [
  {
    type: "Autocomplete",
    elementAutoComplete: {
      id: "death",
      label: "nameOfTheDeceased",
      name: "death",
      options: options,
      onChangeTextField: (data: string) => onChange(data),
      addComponentInOption: (
        option: IAssetAutoCompleteField<unknown>
      ) => <ImgInForm option={option} />
      
    }
  }
];