import React from "react";

// Forms
import { Control, Controller } from "react-hook-form";

// Ui
import { TextField } from "@mui/material";

// Type
import { IElementTextField } from "./types";
import { useTranslation } from "react-i18next";

interface ITextfield {
    element: IElementTextField;
    control: Control;
    error?: boolean;
    required?: boolean;
}

const TextFieldReactHook = ({
  element,
  control,
  error,
  required,
}: ITextfield) => {
  const {t} = useTranslation();
  return (
    <Controller
      name={element.name}
      control={control}
      rules={{required}}
      render={({field: {onChange, value}}) => (
        <TextField
          disabled={element.disabled && true}
          focused={Boolean(element.focused)}
          maxRows={element.maxRows}
          multiline={element.multiline ? element.multiline : false}
          sx={{ width: "100%" }}
          error={error}
          autoComplete="off"
          onChange={onChange}
          label={`${t(element.label)} ${
            element.required === false ? "(" + t("optional") + ")" : ""
          }`}
          id={element.id}
          type={element.type}
          value={value ? value : ""}
          rows={element.rows ? element.rows : undefined}
          variant={element.variant ? element.variant : "standard"}
          InputProps={{
            endAdornment: element.InputProps &&
             element.InputProps.endAdornment ?
              element.InputProps.endAdornment : undefined,
          }}
        />
      )}
    />
  );
};

export default TextFieldReactHook;