import { IForm } from "../../types";

export const resetPasswordForm: IForm[] = [
  {
    type: "TextField",
    elementTextField: {
      id: "email",
      type: "email",
      name: "email",
      label: "email",
      variant: "standard",
    },
  },
];
