import React from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Button, List, ListItem, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormComponent from "../../forms/formcomponent";
import { CardUi } from "../cardUi";
import { useState } from "react";
import { searchProducts } from "../../../backend/queries/product";
import { collections, IDataFrontLinkStockOrKit } from "type-absenso";
import { IAssetAutoCompleteField } from "../../forms/types";
import { IFrontDeath } from "../../../backend/queries/types/frontDocument";
import { searchProfileDeath } from "../../../backend/queries/death";
import { useFuneral } from "../../../provider/auth/hooks/useFuneral";
import { ImgInForm } from "../imgInForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useAlert } from "../../../provider/error/hooks/useAlert";
import { postAuth } from "../../../backend/queries/config";
import { linkProductUrl } from "../../../backend/queries/url";
import { useMemo } from "react";

export const LinkKitForm = () => {
  const {t} = useTranslation();
  const {watch, formState, control, setValue, reset} = useForm();
  const funeral = useFuneral();
  const alert = useAlert();

  const [kits, setKits] = useState<Array<string>>([]);
  const [loading, setLoading] = useState<boolean>();
  const [optionsKit, setOptionsKit] = useState<
    Array<IAssetAutoCompleteField<string>>
  >();
  const [optionsDeath, setOptionsDeath] = useState<
    Array<IAssetAutoCompleteField<IFrontDeath>>
  >();

  const resetAll = () => {
    reset();
    setKits([]);
  };

  const searchKitHandler = async (text: string) => {
    if (text) {
      const data = await searchProducts(collections.kit, text);
      setOptionsKit(data.map(el => {
        return {
          label: el,
          value: el,
        };
      }));
    }
  };

  const searchDeathHandler = async (text: string) => {
    if (text && funeral) {
      const data = await searchProfileDeath({
        search: text,
        authUser: funeral.authorizedUser,
        uidFuneral: funeral.id,
      });

      setOptionsDeath(data.map(el => {
        return {
          label: el.fullname,
          image: el.profilePicture ? el.profilePicture : undefined,
          value: el
        };
      }));
    }
  };

  const addKit = () => {
    if (watch("kit")) {
      const kitData: IAssetAutoCompleteField<string> = watch("kit");

      if (!kits.find(k => kitData.value === k)) {
        setKits([...kits, kitData.value]);
        setValue("kit", undefined);
      }
    }
  };

  const goodData = useMemo(() => {
    return Boolean(kits.length > 0 && watch("deceased"));
  }, [kits, watch("deceased")]);

  const onSubmit = async () => {
    setLoading(true);
    if (kits.length > 0 && watch("deceased")) {
      const dataDeath: IAssetAutoCompleteField<IFrontDeath> = watch("deceased");
      const dataToSend: IDataFrontLinkStockOrKit = {
        products: kits,
        death: dataDeath.value.id
      };

      try {
        await postAuth(
          linkProductUrl,
          dataToSend,
          () => alert && alert.createAlert && alert?.createAlert({
            type: "error",
            message: t("errorStandard")
          })
        );

        alert && alert.createAlert && alert.createAlert({
          type: "success",
          message: t("successKitLinked")
        });
        reset();
        setKits([]);
      } catch (error) {
        alert.standardError();
      }
    }
    setLoading(false);
  };

  return(
    <>
      <CardUi
        title={t("titleLinkKitForm")}
        clearAction={() => resetAll()}
      >
        <Box sx={{mt: 2}}>
          <FormComponent 
            watch={watch}
            formState={formState}
            control={control}
            arrayForms={[
              {
                type: "Autocomplete",
                elementAutoComplete: {
                  id: "kit",
                  name: "kit",
                  label: "searchKit",
                  options: optionsKit || [],
                  onChangeTextField: (text: string) => searchKitHandler(text),
                  required: false
                }
              }
            ]}
          />

          <Button
            sx={{ width: "100%", my: 1 }}
            variant="outlined"
            onClick={addKit}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>

          <FormComponent 
            watch={watch}
            formState={formState}
            control={control}
            arrayForms={[
              {
                type: "Autocomplete",
                elementAutoComplete: {
                  id: "deceased",
                  name: "deceased",
                  label: "nameOfTheDeceased",
                  options: optionsDeath || [],
                  onChangeTextField: (text: string) => searchDeathHandler(text),
                  addComponentInOption: (
                    option: IAssetAutoCompleteField<unknown>
                  ) => <ImgInForm option={option} />
                }
              }
            ]}
          />

          <List>
            {kits.length > 0 && kits.map((el, i) => (
              <ListItem key={i}>
                <Typography>{el}</Typography>
              </ListItem>
            ))}
          </List>

          <LoadingButton
            loading={loading}
            disabled={!goodData}
            variant="contained"
            sx={{width: "100%", mt: 2}}
            onClick={onSubmit}
          >
            {t("link")}
          </LoadingButton>
        </Box>
      </CardUi>
    </>
  );
};