import React from "react";

// Ui
import { Grid } from "@mui/material";
import { BuyCandles } from "../../components/utils/candles/buyCandles";

const HomeCandles = () => {
  return(
    <>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <BuyCandles />
        </Grid>
      </Grid>
    </>
  );
};

export default HomeCandles;
