import React, { ReactNode } from "react";

// Ui
import { Container } from "@mui/material";

const LayoutUnConnected = ({children}: {children: ReactNode}) => {
  return(
    <>
      <Container>
        {children}
      </Container>
    </>
  );
};

export default LayoutUnConnected;
