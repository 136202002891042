/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

// Forms
import {Controller, Control} from "react-hook-form";

// Types
import {IAssetAutocomplete} from "./types/";

// Ui
import {Autocomplete, TextField, Box} from "@mui/material";

// Hooks
import { useTranslation } from "react-i18next";

interface IAutoCompleteReactHooksForms {
    element: IAssetAutocomplete;
    control: Control;
    error?: boolean;
    valueForm?: {[x: string]: any};
    multiple?: boolean;
    onChangeTextField?: (data: string) => void;
  }

const AutoCompleteReactHookForm = ({
  element,
  control,
  error,
  multiple,
  onChangeTextField,
}: IAutoCompleteReactHooksForms) => {
  const {t} = useTranslation();
  return (
    <Controller
      name={element.name}
      control={control}
      rules={{
        required: element.required !== undefined ? element.required : true,
      }}
      render={({ field: { onChange, value } }) => (
        <>
          <Autocomplete
            loading={element.loading}
            ListboxProps={{ style: { maxHeight: 150, overflow: "auto" } }}
            isOptionEqualToValue={(option: any, val) => option.id === val.id}
            multiple={multiple ? true : false}
            value={value ? value : null}
            disablePortal
            id={element.id}
            options={element.options}
            getOptionLabel={(option: any) => {
              return t(option.label);
            }}
            onChange={(_, data) => onChange(data)}
            sx={{ width: "100%" }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
                {...props}
              >
                {element.addComponentInOption &&
                  element.addComponentInOption(option)}
                <Box>{t(option.label)}</Box>
              </Box>
            )}
            renderInput={(params) => (
              <>
                <TextField
                  hiddenLabel={false}
                  variant={element.variant || "outlined"}
                  onChange={
                    onChangeTextField
                      ? (e) => onChangeTextField(e.target.value)
                      : () => true
                  }
                  error={error}
                  label={t(element.label)}
                  {...params}
                />
              </>
            )}
          />
        </>
      )}
    />
  );
};

export default AutoCompleteReactHookForm;