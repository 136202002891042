import { religionTypes } from "type-absenso";
import { IAssetAutoCompleteField, IForm } from "../../types";

interface ICreateDeceasedForm {
    onChangeAddress: (data: string) => void;
    optionsAddress: Array<IAssetAutoCompleteField<string>>
    loading: boolean;
}

const optionsReligion: Array<IAssetAutoCompleteField<religionTypes>> = [
  {
    label: "catholic",
    value: "catholic"
  },
  {
    label: "muslim",
    value: "muslim"
  },
  {
    label: "jewish",
    value: "jewish"
  }
];

export const createdeceasedForm: (
    {
      onChangeAddress,
      optionsAddress,
      loading,
    } : ICreateDeceasedForm) => Array<IForm> = ({
      onChangeAddress,
      optionsAddress,
      loading,
    }: ICreateDeceasedForm) => {
      return [
        {
          type: "TextField",
          elementTextField: {
            id: "name",
            type: "text",
            label: "name",
            name: "name",
            variant: "standard",
          }
        },
        {
          type: "TextField",
          elementTextField: {
            id: "firstName",
            name: "firstName",
            label: "firstName",
            type: "text",
            variant: "standard",
          }
        },
        {
          type: "TextField",
          elementTextField: {
            name: "biography",
            id: "biography",
            label: "biography",
            type: "text",
            rows: 4,
            multiline: true,
            variant: "standard",
            required: false,
          }
        },
        {
          type: "DatePicker",
          elementDatePicker: {
            name: "dateOfBirth",
            label: "dateOfBirth",
            maxDate: new Date(),
            variant: "standard",
          }
        },
        {
          type: "DatePicker",
          elementDatePicker: {
            name: "dateOfDeath",
            label: "dateOfDeath",
            maxDate: new Date(),
            variant: "standard",
          }
        },
        {
          type: "Autocomplete",
          elementAutoComplete: {
            id: "address",
            label: "address",
            name: "address",
            type: "text",
            options: optionsAddress,
            onChangeTextField: (data) => onChangeAddress(data),
            variant: "standard",
            loading,
          }
        },
        {
          type: "Autocomplete",
          elementAutoComplete: {
            required: false,
            id: "religion",
            label: "religion",
            name: "religion",
            options: optionsReligion,
            variant: "standard",
          }
        }
      ];
    };

export interface IDeceasedForm {
    dateOfBirth: Date;
    dateOfDeath: Date;
    name: string;
    firstName: string;
    biography?: string;
    lastAddress: string;
    religion?: IAssetAutoCompleteField<religionTypes>
}