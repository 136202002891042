export const darkTurquoise = "#003848";
export const gray = "#F7F9FC";
export const lightGray = "#ECECEC";
export const gold = "#A17846";
export const gold20 = "rgba(161, 120, 70, 0.2)";
export const blueishWhite = "#EFF5F6";
export const textGray = "#6F6F6F";
export const grayBlue = "#DCDCDC";
export const darkTurquoiseLight = "#074B5F";
export const darkTurquoiseRgb = "rgba(0, 22, 28, 0.05)";
export const backgroundOpacity = "#EFF5F7";
export const borderOpacity = "#E6E6E6";
export const grayDark = "#6F6F6F";
export const grayLight = "#989898";
export const gold05 = "#DAB88B";
export const blueFrame = "#19718A";
// eslint-disable-next-line max-len
export const boxShadow = "0px 3px 25px -2px rgba(0, 0, 0, 0.14), 0px 2px 20px rgba(0, 0, 0, 0.02), 0px 1px 15px rgba(0, 0, 0, 0.03)";

