import React, { useState, useEffect } from "react";

// I18n
import { useTranslation } from "react-i18next";

// Ui
import { Box, Button, Typography } from "@mui/material";
import { gold, gray } from "../../../ui/color";

// Form
import { useForm } from "react-hook-form";
import FormComponent from "../../forms/formcomponent";
import { createdeceasedForm } from "../../forms/asset/deceased/create";
import ImageCropper from "../imageCropper";

// Hooks
import { useAlert } from "../../../provider/error/hooks/useAlert";
import { useFuneral } from "../../../provider/auth/hooks/useFuneral";

// Types
import { IAssetAutoCompleteField } from "../../forms/types";
import {
  IGeolocation,
  collections,
  IDataFrontCreateDeceased,
} from "type-absenso";
import { IDeceasedForm } from "../../forms/asset/deceased/create";

// Backend
import {
  formatAdress,
  getAddressDetails,
} from "../../../backend/queries/address";
import { geohashForLocation } from "geofire-common";
import { LoadingButton } from "@mui/lab";
import { Timestamp, DocumentReference } from "@firebase/firestore";
import {
  getStorage,
  uploadBytesResumable,
  ref,
  getDownloadURL,
} from "@firebase/storage";
import { getAuth } from "@firebase/auth";
import { app } from "../../../backend/config";
import { addDeathProfile } from "../../../backend/queries/death";

// Lib
import { uid } from "uid";

export const CreateDeceased = () => {
  const { t } = useTranslation();
  const funeral = useFuneral();
  const alert = useAlert();
  const auth = getAuth(app);
  const { watch, formState, control, reset, handleSubmit } = useForm();

  const [urlCropProfile, setUrlCropProfile] = useState<string>();
  const [cropProfile, setCropProfile] = useState<File>();
  const [,setErrorCropProfile] = useState<boolean>();

  const [urlCropCover, setUrlCropCover] = useState<string>();
  const [cropCover, setCropCover] = useState<File>();

  const [optionsAdress, setOptionsAddress] =
    useState<Array<IAssetAutoCompleteField<string>>>();
  const [formattedAddress, setFormattedAddress] = useState<string>();
  const [geocode, setGeocode] = useState<IGeolocation>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingAddress, setLoadingAddress] = useState<boolean>(false);

  const onChangeAddress = async (data: string) => {
    setLoadingAddress(true);
    const addresses = await formatAdress({
      errorAction: () =>
        alert &&
        alert.createAlert &&
        alert?.createAlert({
          type: "error",
          message: t("errorStandard"),
        }),
      data,
    });

    if (addresses) {
      setOptionsAddress(
        addresses.map((elt) => {
          return { label: elt.description, value: elt.place_id };
        })
      );
    }
    setLoadingAddress(false);
  };

  useEffect(() => {
    setLoadingAddress(true);
    if (watch("address")) {
      (async () => {
        const data = watch("address") as IAssetAutoCompleteField<string>;
        const details = await getAddressDetails({
          errorAction: () =>
            alert &&
            alert.createAlert &&
            alert?.createAlert({
              type: "error",
              message: t("errorStandard"),
            }),
          placeId: data.value,
        });

        if (details?.result.geometry.location) {
          const hash = geohashForLocation([
            details?.result.geometry.location.lat,
            details?.result.geometry.location.lng,
          ]);
          setFormattedAddress(details?.result.formatted_address);
          setGeocode({
            ...details?.result.geometry.location,
            hash,
          });
        }
        setLoadingAddress(false);
      })();
    }
    setLoadingAddress(false);
  }, [watch("address")]);

  const resetForm = () => {
    reset();
    setGeocode(undefined);
    setCropProfile(undefined);
    setCropCover(undefined);
    setUrlCropProfile(undefined);
    setUrlCropCover(undefined);
  };

  const isFormValid =
    watch("name") &&
    watch("firstName") &&
    watch("dateOfBirth") &&
    watch("dateOfDeath") &&
    watch("address")
      ? true
      : false;

  const onSubmit = async (data: unknown) => {
    if (formattedAddress && geocode && funeral) {
      setLoading(true);
      try {
        const dataForm = data as IDeceasedForm;
        const storage = getStorage(app);
        const deathImgRef = ref(storage, `${collections.death}/${uid(32)}`);
        let coverImgRef, coverUrl, url;
        if (cropProfile) {
          await uploadBytesResumable(deathImgRef, cropProfile);
          url = await getDownloadURL(deathImgRef);
        }
        if (cropCover) {
          coverImgRef = ref(storage, `${collections.death}/${uid(32)}`);
          await uploadBytesResumable(coverImgRef, cropCover);
          coverUrl = await getDownloadURL(coverImgRef);
        }
        if (auth && auth.currentUser) {
          const dataToSend: IDataFrontCreateDeceased<
            DocumentReference,
            Timestamp
          > = {
            admin: [auth.currentUser?.uid],
            funeral: funeral.id,
            profilePicture: url ? url : "default",
            name: dataForm.name,
            firstName: dataForm.firstName,
            fullname: `${dataForm.firstName} ${dataForm.name}`,
            lastAddress: formattedAddress,
            geoLocation: geocode,
            nOfCandlesOrdered: 0,
            dateOfBirth: dataForm.dateOfBirth.toJSON(),
            dateOfDeath: dataForm.dateOfDeath.toJSON(),
            coverPicture: coverUrl ? coverUrl : "default",
            description: dataForm.biography ? dataForm.biography : "",
            religion: dataForm.religion && dataForm.religion.value,
            // eslint-disable-next-line max-len
            fullnameSearch: `${dataForm.firstName.toLowerCase()} ${dataForm.name.toLowerCase()}`,
            firstnameSearch: dataForm.firstName.toLowerCase(),
            nameSearch: dataForm.name.toLowerCase(),
            type: "paid",
          };
          await addDeathProfile({
            data: dataToSend,
            errorAction: () => {
              throw new Error();
            },
          });
          resetForm();
        }

        alert &&
          alert.createAlert &&
          alert?.createAlert({
            type: "success",
            message: t("successDesceaded"),
          });
      } catch (error) {
        alert &&
          alert.createAlert &&
          alert?.createAlert({
            type: "error",
            message: t("errorStandard"),
          });
      }
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        padding: 2,
        mb: {
          md: 12,
          xs: 18,
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: 0,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontSize: "1.2rem",
            textTransform: "uppercase",
            fontWeight: "600",
            color: gold,
          }}
        >
          {t("pictures")}
        </Typography>
        <Box
          sx={{
            py: 2,
            display: "flex",
            flexDirection: {
              md: "row",
              xs: "column",
            },
            gap: 2,
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              width: {
                md: "50%",
                xs: "100%",
              }
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "600",
              }}
            >
              {t("profilePicture")}
            </Typography>
            <ImageCropper
              onCrop={(crop) => setCropProfile(crop)}
              urlCrop={urlCropProfile}
              setUrlCrop={setUrlCropProfile}
              imgStyle={{
                width: "176px",
                height: "176px",
                borderRadius: "50%",
              }}
              id="profilePicture"
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              width: {
                md: "50%",
                xs: "100%",
              }
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "600",
              }}
            >
              {t("coverPicture")}
            </Typography>
            <ImageCropper
              onCrop={(crop) => setCropCover(crop)}
              urlCrop={urlCropCover}
              setUrlCrop={setUrlCropCover}
              aspectRatio={3}
              imgStyle={{
                width: "100%"
              }}
              id="coverPicture"
            />
          </Box>
        </Box>
        <FormComponent
          watch={watch}
          formState={formState}
          control={control}
          arrayForms={createdeceasedForm({
            onChangeAddress,
            optionsAddress: optionsAdress || [],
            loading: loadingAddress,
          }).slice(0, 5)}
        />
        <Typography
          variant="h5"
          sx={{
            fontSize: "1.2rem",
            textTransform: "uppercase",
            fontWeight: "600",
            color: gold,
            my: 2,
          }}
        >
          {t("lastAddress")}
        </Typography>
        <FormComponent
          watch={watch}
          formState={formState}
          control={control}
          arrayForms={createdeceasedForm({
            onChangeAddress,
            optionsAddress: optionsAdress || [],
            loading: loadingAddress,
          }).slice(5, 6)}
        />
        <Typography
          variant="h5"
          sx={{
            fontSize: "1.2rem",
            textTransform: "uppercase",
            fontWeight: "600",
            color: gold,
            my: 2,
          }}
        >
          {t("religion")}
        </Typography>
        <FormComponent
          watch={watch}
          formState={formState}
          control={control}
          arrayForms={createdeceasedForm({
            onChangeAddress,
            optionsAddress: optionsAdress || [],
            loading: loadingAddress,
          }).slice(6, 7)}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          position: "fixed",
          bottom: 0,
          backgroundColor: gray,
          mx: -2,
          borderTop: "1px solid #E0E0E0",
          zIndex: 1000,
        }}
      >
        <Box
          p={2}
          sx={{
            width: {
              md: "calc(100% - 240px)",
              xs: "100%",
            },
            display: "flex",
            flexDirection: {
              md: "row",
              xs: "column",
            },
            alignItems: "center",
          }}
        >
          <LoadingButton
            onClick={() => {
              handleSubmit(onSubmit)();
              !cropProfile && setErrorCropProfile(true);
            }}
            loading={loading}
            variant="contained"
            sx={{
              width: {
                md: "fit-content",
                xs: "100%",
              },
              backgroundColor: gold,
              mx: 2,
              mb: {
                md: 0,
                xs: 2,
              },
            }}
            disabled={!isFormValid}
          >
            {t("createDeceased")}
          </LoadingButton>
          <Button
            variant="contained"
            sx={{
              px: 2,
              width: {
                md: "fit-content",
                xs: "100%",
              },
            }}
            onClick={() => resetForm()}
          >
            {t("reinitiateValues")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
