import React, {useState} from "react";

// Ui
import {
  AppBar,
  Toolbar,
  Drawer,
  Box,
  ListItem,
  ListItemText,
  ListItemButton,
  List,
  IconButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket, faUser } from "@fortawesome/free-solid-svg-icons";
import { blueishWhite, gold, gold20, textGray } from "../../ui/color";
import logoBlack from "../../media/img/logo_black.svg";

// Routing
import { useNavigate } from "react-router-dom";
import { TArrayMenu } from "./type/types";

// i18n
import { useTranslation } from "react-i18next";
import { useAuth } from "../../provider/auth/hooks/useAuth";
import {
  useTitleContext,
} from "../../components/utils/layout/titleContext";

const drawerWidth = 360;

const LayoutAdmin: React.FC<{
  children: React.ReactElement;
  arrayMenu: TArrayMenu;
}> = ({ children, arrayMenu }) => {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();
  const { title } = useTitleContext();

  const changePath = (path: string) => {
    navigate(path);
    setOpen(false);
  };

  const signout = () => {
    if (auth) {
      navigate("/");
      auth.signout();
    }
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer - 1,
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginLeft: {
              md: `${drawerWidth}px`,
              xs: "0px",
            },
            backgroundColor: "white",
          }}
        >
          <Typography
            variant="h5"
            component="div"
            sx={{
              textAlign: "left",
              color: "black",
              fontWeight: "600",
              fontSize: "15px",
              display: { md: "block", xs: "none" },
            }}
          >
            {t(title)}
          </Typography>
          <IconButton
            sx={{
              color: { gold },
              display: { md: "none", xs: "flex" },
              alignItems: "center",
            }}
            onClick={() => setOpen(open ? false : true)}
          >
            <MenuIcon
              sx={{
                color: gold,
              }}
            />
          </IconButton>
          <Box
            sx={{
              display: { md: "none", xs: "flex" },
              marginLeft: -3,
            }}
          >
            <img
              src={logoBlack}
              style={{
                width: "125px",
                margin: "auto",
              }}
              alt="logo"
            />
          </Box>
          <Box></Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          display: { md: "block", xs: "none" },
          width: drawerWidth,
          flexShrink: 0,
          backgroundColor: blueishWhite,
          position: "relitive",
          ["& .MuiDrawer-paper"]: {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: blueishWhite,
          },
        }}
      >
        <img
          src={logoBlack}
          style={{
            width: "150px",
            margin: "0 auto",
            marginTop: 36,
            marginBottom: 36,
          }}
          alt="logo"
        />
        <Box
          sx={{
            overflow: "auto",
            padding: 2,
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                backgroundColor: "white",
                alignItems: "center",
                justifyContent: "space-between",
                py: 1,
                px: 2,
                borderRadius: "5px",
              }}
            >
              {auth?.userData?.pictures !== "default" ? (
                <img
                  src={auth?.userData?.pictures}
                  style={{
                    width: "40px",
                    height: "40px",
                    objectFit: "cover",
                    alignContent: "center",
                    borderRadius: "50%",
                  }}
                  alt="logo"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faUser}
                  style={{ fontSize: "40px" }}
                  color={gold}
                  height={30}
                  width={30}
                />
              )}
              <Box sx={{ width: "80%" }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    textAlign: "left",
                    color: gold,
                    fontWeight: "bold",
                    fontSize: "13px",
                  }}
                >
                  {t("connectedAs")}
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    textAlign: "left",
                    color: "black",
                    fontWeight: "500",
                    fontSize: "18px",
                  }}
                >
                  {auth?.userData?.firstName} {auth?.userData?.name}
                </Typography>
                <Typography variant="h6"
                  component="div"
                  sx={{
                    textAlign: "left",
                    fontSize: "12px",
                    opacity: 0.6
                  }}
                >
                  {auth?.funeralState?.companyName}
                </Typography>
              </Box>
            </Box>
            <List>
              {arrayMenu.map((text, i) => (
                <ListItem key={i} disablePadding>
                  <ListItemButton
                    onClick={() => changePath(text.path)}
                    sx={{
                      "&:hover": {
                        backgroundColor: gold20,
                        color: gold,
                      },
                      borderRadius: "10px",
                    }}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon
                        icon={text.icon}
                        style={{ fontSize: "18px" }}
                        color={textGray}
                      />
                    </ListItemIcon>
                    <ListItemText>{t(text.title)}</ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
          <List>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => signout()}
                sx={{
                  "&:hover": {
                    backgroundColor: gold20,
                    color: gold,
                  },
                  borderRadius: "10px",
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    style={{ fontSize: "18px" }}
                    color={textGray}
                  />
                </ListItemIcon>
                <ListItemText>{t("logout")}</ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>

      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        variant="temporary"
        sx={{
          display: { md: "none", xs: "block" },
          width: drawerWidth,
          flexShrink: 0,
          backgroundColor: blueishWhite,
          ["& .MuiDrawer-paper"]: {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: blueishWhite,
          },
        }}
      >
        {/* <Toolbar sx={{ position: "initial" }} /> */}
        <img
          src={logoBlack}
          style={{
            width: "150px",
            margin: "0 auto",
            marginTop: 22,
            marginBottom: 12,
          }}
          alt="logo"
        />
        <Box
          sx={{
            display: "flex",
            backgroundColor: "white",
            alignItems: "center",
            justifyContent: "space-between",
            margin: 2,
            py: 1,
            px: 2,
            borderRadius: "5px",
          }}
        >
          {auth?.userData?.pictures !== "default" ? (
            <img
              src={auth?.userData?.pictures}
              style={{
                width: "40px",
                height: "40px",
                objectFit: "cover",
                alignContent: "center",
                borderRadius: "50%",
              }}
              alt="logo"
            />
          ) : (
            <FontAwesomeIcon
              icon={faUser}
              style={{ fontSize: "40px" }}
              color={gold}
              height={30}
              width={30}
            />
          )}
          <Box sx={{ width: "80%" }}>
            <Typography
              variant="h6"
              component="div"
              sx={{
                textAlign: "left",
                color: gold,
                fontWeight: "bold",
                fontSize: "13px",
              }}
            >
              {t("connectedAs")}
            </Typography>
            <Typography
              variant="h6"
              component="div"
              sx={{
                textAlign: "left",
                color: "black",
                fontWeight: "500",
                fontSize: "18px",
              }}
            >
              {auth?.userData?.name}
            </Typography>
            <Typography variant="h6"
              component="div"
              sx={{
                textAlign: "left",
                fontSize: "12px",
                opacity: 0.6
              }}
            >
              {auth?.funeralState?.companyName}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ overflow: "auto", px: 2 }}>
          <List sx={{ minHeight: "calc(100vh - 200px)", position: "relative" }}>
            {arrayMenu.map((text, i) => (
              <ListItem key={i} disablePadding>
                <ListItemButton
                  onClick={() => changePath(text.path)}
                  sx={{
                    "&:hover": {
                      backgroundColor: gold20,
                      color: gold,
                    },
                    borderRadius: "10px",
                  }}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon
                      icon={text.icon}
                      style={{ fontSize: "23px" }}
                      color={textGray}
                    />
                  </ListItemIcon>
                  <ListItemText>{t(text.title)}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}

            <ListItem
              disablePadding
              sx={{
                position: "absolute",
                bottom: 0,
              }}
            >
              <ListItemButton onClick={() => signout()}>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    style={{ fontSize: "23px" }}
                    color={textGray}
                  />
                </ListItemIcon>
                <ListItemText>{t("logout")}</ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)`, xs: "100%" },
          minHeight: (theme) => `calc(100vh - ${theme.spacing(8)})`,
          overflow: "hidden",
          position: "relative",
          flexGrow: 1,
          p: 0,
          mt: (theme) => theme.spacing(8),
          float: "right",
          backgroundColor: "white",
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default LayoutAdmin;