import React, { useEffect } from "react";

// I18n
import { Box, Typography } from "@mui/material";
import { gold } from "../../ui/color";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { getAuth } from "firebase/auth";
import { app } from "../../backend/config";
import { useAlert } from "../../provider/error/hooks/useAlert";
import { getDeathWithAdmin } from "../../backend/queries/death";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTitleContext } from "../../components/utils/layout/titleContext";

const HomeConsultDeceased = () => {
  const { t } = useTranslation();
  const user = getAuth(app).currentUser;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [rows, setRows] = React.useState<GridRowsProp>([]);
  const [columns] = React.useState<GridColDef[]>([
    {
      field: "name",
      headerName: t("name"),
      width: 180,
      headerClassName: "header",
    },
    {
      field: "address",
      headerName: t("address"),
      width: 180,
      headerClassName: "header",
    },
    {
      field: "dateOfBirth",
      headerName: t("dateOfBirth"),
      width: 130,
      headerClassName: "header",
    },
    {
      field: "dateOfDeath",
      headerName: t("dateOfDeath"),
      width: 130,
      headerClassName: "header",
    },
    {
      field: "type",
      headerName: t("type"),
      width: 80,
      headerClassName: "header"
    },
    {
      field: "details",
      type: "actions",
      headerName: t("details"),
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          // eslint-disable-next-line react/jsx-key
          <GridActionsCellItem
            icon={<FontAwesomeIcon icon={faEye} color={gold} size="lg" />}
            label="Edit"
            className="textPrimary"
            onClick={() => getDeceased(id as string)}
            color="inherit"
            key={id}
          />,
        ];
      },
      headerClassName: "header",
    },
  ]);
  const alert = useAlert();
  const {setTitle} = useTitleContext();

  const getDeceased = (id: string) => {
    const baseURL = process.env.REACT_APP_URL_CUSTOMER;
    const url = `${baseURL}/#/memorial/page/${id}`;
    window.open(url);
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (user) {
        setTitle(t("consultDeceased"));
        try {
          const deaths = await getDeathWithAdmin(user.uid);
          const rows = deaths.map((death) => ({
            id: death.id,
            name: death.fullname,
            address: death.lastAddress,
            dateOfBirth: death.dateOfBirth.toDate().toLocaleDateString(),
            dateOfDeath: death.dateOfDeath.toDate().toLocaleDateString(),
            type: death.type
          }));
          setLoading(false);
          setRows(rows);
        } catch (error) {
          setLoading(false);
          alert.standardError();
        }
      }
    })();
  }, [user]);

  return (
    <Box
      sx={{
        padding: 2,
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: 0,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontSize: "1.2rem",
            textTransform: "uppercase",
            fontWeight: "600",
            color: gold,
          }}
        >
          {t("consultDeceased")}
        </Typography>
        <Box
          sx={{
            p: 2,
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            loading={loading}
            sx={{
              height: "80vh",
              "& .header": {
                fontWeight: "700",
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default HomeConsultDeceased;
