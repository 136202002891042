import {
  collection,
  getDocs,
  orderBy,
  query,
  where,
  limit
} from "firebase/firestore";
import { collections, IProduct } from "type-absenso";
import { db } from "../config";
import { IFrontProduct } from "./types/frontDocument";

export const getProducts = async ({
  errorAction
}: {
    errorAction: () => void;
}) => {
  try {
    const dataProduct: Array<IFrontProduct> = [];
    const productRef = collection(db, collections.product);
    const q = query(
      productRef,
      orderBy("name")
    );
    const snaps = await getDocs(q);

    for await (const snap of snaps.docs) {
      const data = snap.data() as IProduct | undefined;
      if (data) {
        dataProduct.push({
          ...data,
          id: snap.id
        });
      }
    }

    return dataProduct;
  } catch (error) {
    errorAction();
  }
};

export const searchProducts = async (
  collectionName: string,
  search: string
) => {
  const dataProduct: Array<string> = [];
  const productRef = collection(db, collectionName);

  const q = query(
    productRef,
    where("__name__", ">=", search.toUpperCase()),
    where("__name__", "<=", search.toLowerCase()+"\uf8ff"),
    limit(10)
  );

  const snaps = await getDocs(q);

  for await (const snap of snaps.docs) {
    dataProduct.push(snap.id);
  }

  return dataProduct;
};
