import { urls } from "type-absenso";
const proxy = process.env.REACT_APP_URL_API;

export const addressFormatUrl = `${proxy}${urls.formatAddress}`;
export const detailsAddressUrl = `${proxy}${urls.detailsAddress}`;
export const createDeathUrl = `${proxy}${urls.createDeath}`;
export const createDeathWithFuneralUrl = 
`${proxy}/v1/create/death/with/funeral`;
export const sendMailUrl = `${proxy}${urls.sendMail}`;
export const linkProductUrl = `${proxy}${urls.linkProductWithDeceased}`;
export const orderCandlesUrl = `${proxy}${urls.orderCandleFuneral}`;
export const updateDeathUrl = `${proxy}${urls.updateDeceased}`;
export const addAdminUrl = `${proxy}${urls.addAdminDeceased}`;