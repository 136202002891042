// Firestore
import { db } from "../config";
import {
  doc,
  getDoc,
  collection,
  where,
  limit,
  getDocs,
  query,
  DocumentReference
} from "@firebase/firestore";

// Types
import { IUsers, collections, IFuneralCompany } from "type-absenso";
import { IFrontFuneral, IFrontUser } from "./types/frontDocument";
import { Timestamp } from "firebase/firestore";

export const getCurrentUser = async ({
  errorAction,
  uid,
}: {
      errorAction: () => void;
      uid: string;
  }) => {
  try {
    const userRef = doc(db, collections.users, uid);
    const snap = await getDoc(userRef);
  
    return snap.data() as IUsers<Timestamp> | undefined;
  } catch (error) {
    errorAction();
  }
};

export const getFuneralCompany = async ({
  errorAction,
  uid,
}: {
  uid: string;
  errorAction: () => void;
}) => {
  try {
    const funeralCompanyRef = collection(db, collections.funeralCompany);
    const q = query(
      funeralCompanyRef,
      where("authorizedUser", "array-contains", doc(db, collections.users, uid))
    );
    const snaps = await getDocs(q);
    const snap = snaps.docs[0];
    const data = snap.data() as IFuneralCompany<DocumentReference>;
    const dataFront: IFrontFuneral = {...data, id: snap.id}; 

    return dataFront;
  } catch (error) {
    errorAction();
  }
};


export const searchUser = async ({
  errorAction,
  search
}: {
  errorAction: () => void;
  search: string;
}) => {
  try {
    const dataUser: Array<IFrontUser> = [];
    const userRef = collection(db, collections.users);

    const q = query(
      userRef,
      where("email", ">=", search.toUpperCase()),
      where("email", "<=", search.toLowerCase()+"\uf8ff"),
      limit(10)
    );
    const snaps = await getDocs(q);

    for await (const snap of snaps.docs) {
      const data = snap.data() as IUsers<Timestamp>;
      dataUser.push({
        ...data,
        id: snap.id,
      });

      return dataUser;
    }
  } catch (error) {
    errorAction();
  }
};