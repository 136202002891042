export const routing = {
  auth: {
    signin: "/auth/signin",
    resetPassword: "/auth/reset-password"
  },
  deceased: {
    index: "/deceased/home",
    consult: "/deceased/consult",
  },
  candle: {
    index: "/candle/home"
  },
  stock: {
    index: "/stock/home"
  }
};