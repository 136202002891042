import React from "react";
import { IAssetAutoCompleteField } from "../forms/types";


import { Box } from "@mui/system";

export const ImgInForm = ({option}: {
    option: IAssetAutoCompleteField<unknown>
}) => {
  if (option.image) {
    return(
      <Box mr={2}>
        <img src={option.image} loading="lazy" style={{
          width: "50px",
          height: "50px",
          borderRadius: "25px"
        }} />
      </Box>
    );
  }
  return(<></>);
};