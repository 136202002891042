import React, { useState } from "react";

// Colors
import { boxShadow, darkTurquoiseLight, gold } from "../../ui/color";

// Components UI
import {
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import logo from "../../media/img/logo.svg";
import VisibilityIcon from "../../components/utils/auth/visibilityIcon";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { LoadingButton } from "@mui/lab";

// Forms
import { useForm } from "react-hook-form";
import FormComponent from "../../components/forms/formcomponent";
import { signinForm } from "../../components/forms/asset/auth/signin";

// I18n
import { useTranslation } from "react-i18next";

// Hooks
import { useAuth } from "../../provider/auth/hooks/useAuth";

// Router
import { Link } from "react-router-dom";
import { routing } from "../../router/config";

const Signin = () => {
  const { control, watch, formState, handleSubmit } = useForm();
  const { t } = useTranslation();
  const auth = useAuth();
  const [visibility, setVisibility] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = (data: { email: string; password: string }) => {
    if (auth) {
      setLoading(true);
      auth.signin(data.email, data.password);
      setLoading(false);
    }
    
  };

  const handleClickShowPassword = () => setVisibility((show) => !show);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          maxWidth: "500px",
          height: "100vh",
          margin: "0 auto",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            boxShadow: boxShadow,
            borderRadius: "10px",
            width: "100%",
          }}
        >
          <Box
            py={2}
            sx={{
              backgroundColor: darkTurquoiseLight,
              width: "100%",
              maxWidth: "600px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{
                width: "280px",
                objectFit: "cover",
                marginTop: 2,
              }}
            />
            <Typography
              variant="h4"
              component="h4"
              sx={{
                color: "white",
                textTransform: "uppercase",
                marginTop: 4,
                marginBottom: 2,
                letterSpacing: "1px",
                fontSize: theme => theme.spacing(3),
              }}
            >
              {t("auth.signin")}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              maxWidth: "600px",
            }}
            p={4}
          >
            <FormComponent
              control={control}
              watch={watch}
              formState={formState}
              arrayForms={signinForm(
                visibility ? "text" : "password",
                <VisibilityIcon
                  showPassword={handleClickShowPassword}
                  visibility={visibility}
                />
              )}
            />
            <Box
              my={1}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                sx={{
                  fontSize: (theme) => theme.spacing(2),
                  lineHeight: (theme) => theme.spacing(3),
                }}
                control={<Checkbox defaultChecked />}
                label={t("auth.stayConnected")}
              />

              <Link 
                to={routing.auth.resetPassword} 
                style={{ textDecoration: "none" }}>
                <Button variant="text">{t("auth.forgotPassword")}</Button>
              </Link>
            </Box>
            <LoadingButton
              onClick={handleSubmit(onSubmit as never)}
              id="btn-login"
              sx={{
                width: "100%",
                maxWidth: "160px",
                mt: 4,
                backgroundColor: gold,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              variant="contained"
              loading={loading}
            >
              {t("auth.signin")}
              <ArrowForwardIosIcon sx={{
                fontSize: (theme) => theme.spacing(2),
              }} />
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Signin;
