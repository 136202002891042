import React, { useEffect, useState } from "react";

// I18n
import { useTranslation } from "react-i18next";

// Ui
import { CardUi } from "../cardUi";
import { Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// Form
import FormComponent from "../../forms/formcomponent";
import { useForm } from "react-hook-form";
import {
  IOrderCandlesForm,
  orderCandlesForm
} from "../../forms/asset/candles/orderCandles";

// Types
import { IAssetAutoCompleteField } from "../../forms/types";
import { IDataFrontOrderCandles } from "type-absenso";

// Hooks
import { useAlert } from "../../../provider/error/hooks/useAlert";
import { useFuneral } from "../../../provider/auth/hooks/useFuneral";
import { useUser } from "../../../provider/auth/hooks/useUser";

// Backend
import { getProducts } from "../../../backend/queries/product";
import { formatAdress } from "../../../backend/queries/address";
import { postAuth } from "../../../backend/queries/config";
import { orderCandlesUrl } from "../../../backend/queries/url";
import { searchProfileDeath } from "../../../backend/queries/death";


export const BuyCandles = () => {
  const {t} = useTranslation();
  const alert = useAlert();
  const funeral = useFuneral();
  const user = useUser();
  const {watch, formState, control, handleSubmit, reset, setValue} = useForm();

  const [searchDeath, setSearchDeath] = useState<string>();
  const [optionsDeath, setOptionsDeath] = useState<
    Array<IAssetAutoCompleteField<string>>
  >();

  const [loading, setLoading] = useState<boolean>();
  const [optionsAdress, setOptionsAddress] = useState<
    Array<IAssetAutoCompleteField<string>>
  >();

  const [optionsProducts, setOptionsProduct] = useState<
    Array<IAssetAutoCompleteField<string>>
  >();

  useEffect(() => {
    if (funeral && user) {
      const address: IAssetAutoCompleteField<string> = {
        label: funeral.deliveryAddress,
        value: funeral.deliveryAddress
      };
      setValue("deliveryAddress", address);
      setValue("postalCodeDelivery", funeral.postalCode);
      setValue("deliveryCity", funeral.city);
      setValue("deliveryCountry", funeral.country);
      setValue("name", user.name);
      setValue("firstName", user.firstName);
    }
  }, [funeral]);

  useEffect(() => {
    (async() => {
      const products = await getProducts({
        errorAction: () => alert && alert.createAlert && alert?.createAlert({
          type: "error",
          message: t("errorStandard")
        })
      });

      if (products) {
        const options: Array<IAssetAutoCompleteField<string>> = products?.map(
          el => {
            return {
              label: el.name, value: el.id,
            };
          }
        );

        setOptionsProduct(options);
      }
    })();
  }, []);

  const onChangeAddress = async (data: string) => {
    const addresses = await formatAdress({
      errorAction: () => alert && alert.createAlert && alert?.createAlert({
        type: "error",
        message: t("errorStandard")
      }),
      data,
    });

    if (addresses) {
      setOptionsAddress(addresses.map(elt => {
        return {label: elt.description, value: elt.place_id};
      }));
    }

  };

  useEffect(() => {
    (async() => {
      if (searchDeath && funeral) {
        try {
          const deaths = await searchProfileDeath({
            authUser: funeral?.authorizedUser,
            search: searchDeath,
            uidFuneral: funeral.id
          });
  
          if (deaths) {
            const arrayD: Array<
            IAssetAutoCompleteField<string>
          > = deaths.map(elt => {
            const dateOfDeath = elt.dateOfDeath.toDate();
            const dateString = dateOfDeath.toLocaleDateString("eu-FR");
            return {
              label: `${elt.fullname} ${dateString}`,
              value: elt.id,
              image: elt.profilePicture ? elt.profilePicture : undefined,
            };
          });
  
            setOptionsDeath(arrayD);
          }
        } catch (error) {
          alert && alert.createAlert && alert?.createAlert({
            type: "error",
            message: t("errorStandard")
          });
        }
      }
    })();
  }, [searchDeath]);

  const onSubmit = async (data: IOrderCandlesForm) => {
    setLoading(true);
    if (funeral?.id) {
      const dataToSend: IDataFrontOrderCandles = {
        deliveryAddress: data.deliveryAddress.label,
        numberOfOrders: parseInt(data.numberOfOrders),
        postalCodeDelivery: data.postalCodeDelivery,
        product: data.product.value,
        uidFuneral: funeral?.id,
        death: data.death?.value || undefined,
        deliveryCity: data.deliveryCity,
        deliveryCountry: data.deliveryCountry,
        firstName: data.firstName,
        linkDeceased: data.linkDeceased || false,
        name: data.name,
      };

      await postAuth(
        orderCandlesUrl,
        dataToSend,
        () => alert && alert.createAlert && alert?.createAlert({
          type: "error",
          message: t("errorStandard")
        })
      );

      alert && alert.createAlert && alert?.createAlert({
        type: "success",
        message: t("succesOrderCandles")
      });
    
      reset();
    }
    setLoading(false);

  };

  const formatForm = () => {
    return orderCandlesForm({
      options: optionsProducts || [],
      onChangeAddress,
      optionsAdress: optionsAdress || [],
      optionsDeath: optionsDeath || [],
      onChangeDeath: (data: string) => setSearchDeath(data)
    }).filter((el) => {
      if (watch("linkDeceased")) {
        return true;
      }

      return el.elementAutoComplete?.name !== "death";
    });
  };

  return(
    <>
      <CardUi title={t("orderCandles")}>
        <Box py={2}>
          <FormComponent 
            watch={watch}
            formState={formState}
            control={control}
            arrayForms={formatForm()}
          />
          <Box pt={2}>
            <LoadingButton
              loading={loading}
              onClick={handleSubmit(onSubmit as never)}
              variant="contained"
              sx={{width: "100%"}}
            >
              {t("orderAt")}
            </LoadingButton>
          </Box>
        </Box>
      </CardUi>
    </>
  );
};