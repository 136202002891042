import React from "react";

// I18n
import { CreateDeceased } from "../../components/utils/deceased/createDeceased";
import { useTitleContext } from "../../components/utils/layout/titleContext";

const HomeDeceased = () => {
  const {setTitle} = useTitleContext();

  React.useEffect(() => {
    setTitle("createDeceased");
  }, []);

  return (
    <>
      <CreateDeceased />
    </>
  );
};

export default HomeDeceased;
